import standard_logo from '../assets/Long_2.png';
import mobile_logo from '../assets/Long_1.png';
import sticky_header_logo from '../assets/Long_Retina.png';
import logo from '../assets/Long_Retina.png';
import { useState, useEffect, useRef } from 'react';

export const SiteHeading = () => {
    const [offset, setOffset] = useState(0);
    const [ searchOpen, setSearchOpen] = useState(false);

    const [searchTerm, setSearchField] = useState('')

    const classNames = (...args) => {
        return args.filter(Boolean).join(' ')
    }

    const ref = useRef(null);
    const searchField = useRef();

    const hashParam = new URLSearchParams(window.location.hash.replace('#',''))

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, [])
    
    const clickInside = (e) => { 
        ref.current = e.target; 
        setSearchOpen( val => !val ) 
    }

    useEffect(() => {
        const clickOutside = () => setSearchOpen(e => false)
        const handlePageClick = (e) => { 
            if(ref.current!=e.target && searchField.current != e.target) 
            clickOutside && clickOutside() 
        }

        window.removeEventListener('click', handlePageClick, true);
        window.addEventListener('click', handlePageClick, { passive: true})
        return window.removeEventListener('click', handlePageClick, true);
    },[])

    const isCurrentUrl = (value) => {
        const token = hashParam.toString().replace('=','')
        return (value.toString() == token) ? ' current-menu-item' : token;
    }

    return (
        <header className="fusion-header-wrapper fusion-is-sticky" style={{position: "relative", height: "135px", zIndex: 5 }}>
            <div className="fusion-header-v7 fusion-logo-alignment fusion-logo-center fusion-sticky-menu- fusion-sticky-logo-1 fusion-mobile-logo-1  fusion-mobile-menu-design-modern">
                <div className="fusion-header-sticky-height" style={{height: '135px', overflow: 'visible'}}></div>
                <div className={classNames("fusion-header", offset>10 && 'fusion-sticky-shadow' )} style={{ height: '135px', overflow: 'visible', top: '0px', backgroundColor: offset>10 ? '#fff' : 'transparent'}}>
<div className="fusion-row fusion-middle-logo-menu">
    <nav className="fusion-main-menu fusion-main-menu-sticky" aria-label="Main Menu">
        <ul id="menu-main" className="fusion-menu fusion-middle-logo-ul">
            <li id="menu-item-15" className={"menu-item menu-item-type-custom menu-item-object-custom menu-item-15 " + isCurrentUrl('about') } data-item-id="15">
                <a href="/#about" className="fusion-top-level-link fusion-bottombar-highlight">
                    <span className="menu-text">ABOUT</span>
                </a>
            </li>
            <li id="menu-item-16" className={"menu-item menu-item-type-custom menu-item-object-custom menu-item-16" + isCurrentUrl('ambiance') } data-item-id="16">
                <a href="/#ambiance" className="fusion-top-level-link fusion-bottombar-highlight">
                    <span className="menu-text">AMBIANCE</span>
                </a>
            </li>	
            <li className="fusion-middle-logo-menu-logo fusion-logo" data-margin-top="20px" data-margin-bottom="31px" data-margin-left="0px" data-margin-right="0px">
                <a className="fusion-logo-link" href="/#home">
            
<img src={standard_logo}  width="479" height="64" style={{maxHeight:'64px',height:'auto'}} alt="Babirwa Fruit Exports Logo" data-retina_logo_url="https://babirwafruit.com/wp-content/uploads/2020/01/Long_Retina.png" className="fusion-standard-logo" />

    <img src={mobile_logo} width="250" height="33" style={{maxHeight:'33px',height:'auto'}} alt="Babirwa Fruit Exports Logo" data-retina_logo_url="https://babirwafruit.com/wp-content/uploads/2020/01/Long_2.png" className="fusion-mobile-logo" />

    <img src={standard_logo}  width="958" height="128" style={{maxHeight:'128px',height:'auto'}} alt="Babirwa Fruit Exports Logo" data-retina_logo_url="https://babirwafruit.com/wp-content/uploads/2020/01/Long_Retina.png" className="fusion-sticky-logo" />
        </a>
</li>

<li id="menu-item-14" className={"menu-item menu-item-type-custom menu-item-object-custom menu-item-14" + isCurrentUrl('packinghouse') } data-item-id="14">
    <a href="#packinghouse" className="fusion-top-level-link fusion-bottombar-highlight">
        <span className="menu-text">PACKING HOUSE</span>
    </a>
</li>
<li id="menu-item-17" className={"menu-item menu-item-type-custom menu-item-object-custom menu-item-17" + isCurrentUrl('contact') } data-item-id="17">
    <a href="/#contact" className="fusion-top-level-link fusion-bottombar-highlight">
        <span className="menu-text">CONTACT</span>
    </a>
</li>
<li className={"fusion-custom-menu-item fusion-main-menu-search" + (searchOpen ? ' fusion-main-menu-search-open':'')}>
    <a className="fusion-main-menu-icon" href="/#" aria-label="Search" data-title="Search" title="Search" role="button" aria-expanded="false" onClick={clickInside}></a>
    <div className="fusion-custom-menu-item-contents" style={{display: searchOpen ? 'block':'none'}}>		
    <form role="search" className="searchform fusion-search-form  fusion-search-form-classNameic" method="get" action="https://babirwafruit.com/">
    <div className="fusion-search-form-content">
    <div className="fusion-search-field search-field">
        <label>
            <span className="screen-reader-text">Search for:</span>
            <input ref={searchField} type="search" value={searchTerm} name="s" className="s" placeholder="Search..." required="" aria-required="true" onChange={(e) => {setSearchField(e.target.value)}} aria-label="Search..." />
        </label>
    </div>
    <div className="fusion-search-button search-button">
        <input type="submit" className="fusion-search-submit searchsubmit" style={{color: '#fff'}} aria-label="Search" onChange={() => {}} value="" />
    </div>

    
</div>



</form>
</div></li>

</ul>
</nav>	

<div className="fusion-mobile-menu-icons">
                <a href="https://babirwafruit.co.za/#" className="fusion-icon awb-icon-bars" aria-label="Toggle mobile menu" aria-expanded="false" aria-controls="mobile-menu-main"></a>

        <a href="https://babirwafruit.co.za/#" className="fusion-icon awb-icon-search" aria-label="Toggle mobile search"></a>

</div>

<nav className="fusion-mobile-nav-holder fusion-mobile-menu-text-align-left" aria-label="Main Menu Mobile" style={{display: 'none'}}>
    <ul id="mobile-menu-main" className="fusion-menu">
        <li id="mobile-menu-item-15" className="fusion-mobile-nav-item current-menu-item" data-item-id="15" >
            <a href="#about" className="fusion-top-level-link fusion-bottombar-highlight">
                <span className="menu-text">ABOUT</span>
            </a>
        </li>
        <li id="mobile-menu-item-16" className="fusion-mobile-nav-item" data-item-id="16" >
            <a href="https://babirwafruit.com/#ambiance" className="fusion-top-level-link fusion-bottombar-highlight">
                <span className="menu-text">AMBIANCE</span>
            </a>
        </li>	
        <li id="mobile-menu-item-14" className="fusion-mobile-nav-item" data-item-id="14">
            <a href="https://babirwafruit.com/#packinghouse" className="fusion-top-level-link fusion-bottombar-highlight">
                <span className="menu-text">PACKING HOUSE</span>
            </a>
        </li>
        <li id="mobile-menu-item-17" className="fusion-mobile-nav-item" data-item-id="17" >
            <a href="https://babirwafruit.com/#contact" className="fusion-top-level-link fusion-bottombar-highlight">
                <span className="menu-text">CONTACT</span>
            </a>
        </li>
    </ul>
</nav>


<div className="fusion-clearfix"></div>
<div className="fusion-mobile-menu-search" style={{display: 'none'}}>
<form role="search" className="searchform fusion-search-form  fusion-search-form-classNameic" method="get" action="https://babirwafruit.com/">
<div className="fusion-search-form-content">

    
    <div className="fusion-search-field search-field">
        <label><span className="screen-reader-text">Search for:</span>
                                        <input type="search" value="" name="s" className="s" placeholder="Search..." required="" aria-required="true" aria-label="Search..." onChange={() => {}}/>
                                </label>
    </div>
    <div className="fusion-search-button search-button">
        <button type="submit" className="fusion-search-submit searchsubmit" aria-label="Search" value="" onChange={() => {}}></button>
    </div>

    
</div>



</form>
</div>
</div>
</div>
    
    


            </div>
            <div className="fusion-clearfix"></div>

        </header>

    )
}