import bgImage from '../assets/DSC5644-HDR-300x200.jpg';

export const AmbianceFarm = () => {
    const imagesList = ['DSC5753.jpg','DSC5774.jpg','DSC5777.jpg','DSC5780.jpg','DSC5798.jpg','DSC5813.jpg','DSC5904.jpg','DSC5846.jpg','DSC5792.jpg','DSC5599-HDR.jpg','DSC5644-HDR.jpg','DSC5711-HDR.jpg']
    const imagePositions = [
        { left: '0px', top: '0px'},
        { left: '370px', top: '0px'},
        { left: '740px', top: '0px'},
        { left: '0px', top: '250px'},
        { left: '370px', top: '250px'},
        { left: '740px', top: '250px'},
        { left: '0px', top: '500px'},
        { left: '370px', top: '500px'},
        { left: '740px', top: '500px'},
        { left: '0px', top: '750px'},
        { left: '370px', top: '750px'},
        { left: '740px', top: '750px'}
    ]
    return (
            <div id="Ambiance" className="fusion-container-anchor">
            <div className="fusion-fullwidth fullwidth-box fusion-builder-row-3 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ borderRadiusTopLeft:'0px', borderRadiusTopRight:'0px', borderRadiusBottomRight:'0px', borderRadiusBottomLeft:'0px' }}>
            <div className="fusion-builder-row fusion-row">
            <div className="fusion-layout-column fusion_builder_column fusion-builder-column-5 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{bgSize:'cover'}}>
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
            <div className="fusion-menu-anchor" id="ambiance">
            </div>
            <div className="fusion-clearfix">
            </div>
            </div>
            </div>
            <div className="fusion-layout-column fusion_builder_column fusion-builder-column-6 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{bgSize:'cover'}}>
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
            <div className="fusion-sep-clear">
            </div>
            <div className="fusion-separator fusion-full-width-sep" style={{marginLeft: 'auto', marginRight: 'auto', marginTop:'10px', marginBottom:'10px', width:'100%'}}>
            </div>
            <div className="fusion-sep-clear">
            </div>
            <div className="fusion-clearfix">
            </div>
            </div>
            </div>
            <div className="fusion-layout-column fusion_builder_column fusion-builder-column-7 fusion_builder_column_3_5 3_5 fusion-three-fifth fusion-column-first" style={{bgSize:'cover', width:'60%', width:`calc(60% - ( ( 4% ) * 0.6 ) )`, marginRight: '4%' }}>
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
            <div className="fusion-text fusion-text-2">
            <h2 data-fontsize="26" style={{ fontSize:26, lineHeight: 1.5 }} data-lineheight="39px" className="fusion-responsive-typography-calculated">
            Ambiance Farm</h2>

            <p style={{ textAlign: 'left'}}>
            Our table grape farm is named Ambiance, in keeping with local naming convention in the Brandwacht area of Worcester, Western Cape, South Africa. But it’s also because this name is beautifully descriptive of who and what we are, encapsulating our closely guarded values of honesty, sustainability, responsibility and agricultural innovation.</p>

            <p style={{ textAlign: 'left'}}>
            Ambiance nestles on the slopes of the Worcester mountains. Covering some 196.69ha, the farm produces many different high quality sought-after seedless varieties of table grapes such as ARRA 15, Sable, Adora, Sweet Joy, Sweet Sapphire, Midnight Beauty, Autumn Crisp, Sweet Globe, Crimson, Sweet Celebration and Joy Bells over about 50ha of cultivated vines.</p>

            </div>
            <div className="fusion-alignleft">
            <a className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span fusion-button-default-type" style={{ button_accent_color:'#ffffff', button_accent_hover_color:'#ffffff', button_border_hover_color:'#ffffff', buttonBorderRadiusTopLeft:'3px', buttonBorderRadiusTopRight:'3px', buttonBorderRadiusBottomRight:'3px', buttonBorderRadiusBottomLeft:'3px', buttonBradientTopColor:'#d8b258', buttonGradientBottomColor:'#d8b258', buttonGradientTopColorHover:'#c39832', buttonBradientBottomColorHover:"#c39832"}} target="_blank" rel="noopener noreferrer" href="../assets/Ambiance-Farm-Map.pdf">
            <span className="fusion-button-text">
            Ambiance Farm Map</span>
            </a>
            </div>
            <div className="fusion-clearfix">
            </div>
            </div>
            </div>
            <div className="fusion-layout-column fusion_builder_column fusion-builder-column-8 fusion_builder_column_2_5 2_5 fusion-two-fifth fusion-column-last" style={{ bgSize:'cover', width:'40%', width:`calc(40% - ( ( 4% ) * 0.4 ) )`}}>
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
            <div className="fusion-image-element fusion-image-align-center in-legacy-container" style={{textAlign:'center', captionTitleSize:`var(--h2_typography-font-size)`, captionTitleTransform:`var(--h2_typography-text-transform)`, captionTitleLineHeight:`var(--h2_typography-line-height)`, captionTitleLetterSpacing:`var(--h2_typography-letter-spacing)`}}>
            <div className="imageframe-align-center">
            <span className=" fusion-imageframe imageframe-dropshadow imageframe-2 hover-type-zoomin" style={{ WebkitBoxShadow: `5px 5px 9px rgba(0,0,0,0.3)`, boxShadow: `5px 5px 9px rgba(0,0,0,0.3)`}}>
            <img decoding="async" width="300" height="200" src={bgImage} alt="" className="img-responsive wp-image-81" sizes={`(max-width: 800px) 100vw, 600px`} />
            </span>
            </div>
            </div>
            <div className="fusion-clearfix">
            </div>
            </div>
            </div>
            <div className="fusion-layout-column fusion_builder_column fusion-builder-column-9 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ bgSize:'cover' }}>
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
            <div className="fusion-sep-clear">
            </div>
            <div className="fusion-separator fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop:'20px', marginBottom:'40px', width:'100%' }}>
            </div>
            <div className="fusion-sep-clear">
            </div>
            <div className="awb-gallery-wrapper awb-gallery-wrapper-1 button-span-no">
            <div style={{ display: 'flex', margin: '-5px',  bordersize:'0px',  position: 'relative',  height: '1000.29px', justContent: 'space-between' }} className="fusion-gallery fusion-gallery-container fusion-grid-3 fusion-columns-total-12 fusion-gallery-layout-grid fusion-gallery-1">
            
            { imagesList.map((imageSrc, index) =>  <div key={index} style={{ padding: '5px', display: 'block', position: 'absolute', left: imagePositions[index].left, top: imagePositions[index].top}} className="fusion-grid-column fusion-gallery-column fusion-gallery-column-3 hover-type-zoomin">
            <div className="fusion-gallery-image">
                <a  href={"../assets/" + imageSrc } rel="noreferrer" data-rel={`iLightbox[gallery_image_${index}]`} className="fusion-lightbox" target="_self" data-caption="">
                    <img decoding="async" src={"../assets/" + imageSrc } width="1417" height="945" alt="" title="" aria-label="" className="img-responsive wp-image-29" sizes="(min-width: 2200px) 100vw, (min-width: 784px) 363px, (min-width: 712px) 545px, (min-width: 640px) 712px, " />
                </a>
            </div>
            </div> 
            
            )}

            {/* <div className="clearfix"></div> */}

            </div>
            </div>

            <div className="fusion-sep-clear">
            </div>
            <div className="fusion-separator fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop:'20px', marginBottom:'40px', width:'100%'}}>
            </div>
            <div className="fusion-sep-clear">
            </div>
            <div className="fusion-clearfix">
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
    )
}