export const FusionFooter = () => {
    const currentDate = new Date().getFullYear()
    return (
        <div className="fusion-footer">
            <footer id="footer" className="fusion-footer-copyright-area fusion-footer-copyright-center" style={{position: 'relative', zIndex: 3}}>
            <div className="fusion-row">
            <div className="fusion-copyright-content">
            
            <div className="fusion-copyright-notice" style={{paddingBottom: '0px'}}>
            <div>
            © {currentDate} Babirwa Fruit Exports.  All Rights Reserved. Created and managed by <a href="http://babirwafruits.co.za/" target="_blank" title="Hybrid Marketing Agency">Twisted Toast</a>	</div>
            </div>
            <div className="fusion-social-links-footer" style={{display: 'none'}}>
            </div>
            
            </div>
            </div> 
            </footer> 
            </div> 
    )
}