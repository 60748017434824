import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useState, useCallback } from 'react';
import '../App.css';

const containerStyle = {
    width: '500px',
    height: '400px'
};

const center = {
    lat: -33.591140,
    lng: 19.405172
  };

export const ContactBabirwa = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAeqDHgo-BS2uQPeA6MzTH4t8iu3Hgx9gk",
    })

    const [map, setMap] = useState(null)

    const onLoad = useCallback((googleMap) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        googleMap.fitBounds(bounds)
        setMap(googleMap);
    },[])

    const onUnmount = useCallback(() => {
        setMap(null)
    }, [])
    


    return (
                <div id="Contact" className="fusion-container-anchor">
                    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-6 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ borderRadiusTopLeft:'0px', borderRadiusTopRight:'0px', borderRadiusBottomRight:'0px', borderRadiusBottomLeft:'0px', backgroundColor:`rgba(229,229,229,0.74)` }}>
                    <div className="fusion-builder-row fusion-row">
                    <div className="fusion-layout-column fusion_builder_column fusion-builder-column-16 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ bgSize:'cover' }}>
                    <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
                    <div className="fusion-menu-anchor" id="contact">
                    </div>
                    <div className="fusion-sep-clear">
                    </div>
                    <div className="fusion-separator fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop:'20px', marginBottom:'40px', width:'100%' }}>
                    </div>
                    <div className="fusion-sep-clear">
                    </div>
                    <div className="fusion-clearfix">
                    </div>
                    </div>
                    </div>
                    <div className="fusion-layout-column fusion_builder_column fusion-builder-column-17 fusion_builder_column_3_5 3_5 fusion-three-fifth fusion-column-first" style={{ bgSize:'cover', width:'60%', width:`calc(60% - ( ( 4% ) * 0.6 ) )`, marginRight: '4%' }}>
                    <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
                    <div className="fusion-text fusion-text-4">
                    <h2 data-fontsize="26" style={{ fontSize:26,  lineHeight: 1.5 }} data-lineheight="39px" className="fusion-responsive-typography-calculated">
                    Contact Babirwa Fruit Exports</h2>

                    </div>
                    <div className="fusion-sep-clear">
                    </div>
                    <div className="fusion-separator fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop:'10px', marginBottom:'10px', width:'100%' }}>
                    </div>
                    <div className="fusion-sep-clear">
                    </div>

                    <div id="nf-form-2-cont" className="nf-form-cont" aria-live="polite" aria-labelledby="nf-form-title-2" aria-describedby="nf-form-errors-2" role="form">

                    <span id="nf-form-title-2" className="nf-form-title">


                    </span>

                    <div className="nf-form-wrap ninja-forms-form-wrap">

                    <div className="nf-response-msg">
                    </div>

                    <div className="nf-debug-msg">
                    </div>

                    <div className="nf-before-form">
                    <nf-section>


                    </nf-section>
                    </div>

                    <div className="nf-form-layout">
                    <form>

                    <div>

                    <div className="nf-before-form-content">
                    <nf-section>

                    <div className="nf-form-fields-required">Fields marked with an <span className="ninja-forms-req-symbol">*</span>are required</div>


                    </nf-section>
                    </div>

                    <div className="nf-form-content ">
                    <nf-fields-wrap>
                    <nf-field>

                    <div id="nf-field-5-container" className="nf-field-container textbox-container  label-above">

                    <div className="nf-before-field">
                    <nf-section>


                    </nf-section>
                    </div>

                    <div className="nf-field">
                    <div id="nf-field-5-wrap" className="field-wrap textbox-wrap" data-field-id="5">
                    <div className="nf-field-label">
                        <label id="nf-label-field-5" className="">Name <span className="ninja-forms-req-symbol">*</span></label>
                    </div>

                    <div className="nf-field-element">
                       <input type="text" value={name} className="ninja-forms-field nf-element" id="nf-field-5" name="nf-field-5" aria-invalid="false" aria-describedby="nf-error-5" aria-labelledby="nf-label-field-5" aria-required="true" required="" onChange={(e) => {setName(e.target.value)}} />
                    </div>

                    </div>
                    </div>

                    <div className="nf-after-field">
                    <nf-section>
                    <div className="nf-input-limit">
                    </div>
                    <div id="nf-error-5" className="nf-error-wrap nf-error" role="alert">
                    </div>
                    </nf-section>
                    </div>
                    </div>

                    </nf-field>
                    <nf-field>

                    <div id="nf-field-6-container" className="nf-field-container email-container  label-above ">
                    <div className="nf-before-field">
                    <nf-section>
                    </nf-section>
                    </div>

                    <div className="nf-field">
                    <div id="nf-field-6-wrap" className="field-wrap email-wrap" data-field-id="6">

                    <div className="nf-field-label">
                       <label id="nf-label-field-6" className="">Email <span className="ninja-forms-req-symbol">*</span></label>
                    </div>

                    <div className="nf-field-element">
                       <input type="email" value={email} className="ninja-forms-field nf-element" id="nf-field-6" name="email" autoComplete="email" aria-invalid="false" aria-describedby="nf-error-6" aria-labelledby="nf-label-field-6" aria-required="true" required="" onChange={(e) => {setEmail(e.target.value)}}/>
                    </div>

                    </div>
                    </div>

                    <div className="nf-after-field">
                    <nf-section>


                    <div className="nf-input-limit">
                    </div>


                    <div id="nf-error-6" className="nf-error-wrap nf-error" role="alert">
                    </div>

                    </nf-section>
                    </div>

                    </div>

                    </nf-field>
                    <nf-field>

                    <div id="nf-field-7-container" className="nf-field-container textarea-container  label-above ">

                    <div className="nf-before-field">
                    <nf-section>


                    </nf-section>
                    </div>

                    <div className="nf-field">
                    <div id="nf-field-7-wrap" className="field-wrap textarea-wrap" data-field-id="7">




                    <div className="nf-field-label">
                    <label id="nf-label-field-7" className="">Message <span className="ninja-forms-req-symbol">*</span></label>
                    </div>



                    <div className="nf-field-element">

                    <textarea id="nf-field-7" rows="10" value={message} name="nf-field-7" aria-invalid="false" aria-describedby="nf-error-7" className="ninja-forms-field nf-element" aria-labelledby="nf-label-field-7" aria-required="true" required="" onChange={(e) => {setMessage(e.target.value)}}>
                        
                    </textarea>

                    </div>



                    </div>
                    </div>

                    <div className="nf-after-field">
                    <nf-section>


                    <div className="nf-input-limit">
                    </div>


                    <div id="nf-error-7" className="nf-error-wrap nf-error" role="alert">
                    </div>



                    </nf-section>
                    </div>

                    </div>

                    </nf-field>
                    <nf-field>

                    <div id="nf-field-8-container" className="nf-field-container submit-container  label-above  textbox-container">

                    <div className="nf-before-field">
                    <nf-section>


                    </nf-section>
                    </div>

                    <div className="nf-field">
                    <div id="nf-field-8-wrap" className="field-wrap submit-wrap textbox-wrap" data-field-id="8">

                    <div className="nf-field-label">
                    </div>

                    <div className="nf-field-element">

                    <input id="nf-field-8" className="ninja-forms-field nf-element btn-dark" type="submit" value="Submit" onChange={() => {}} disabled={ !name || !email || !message }/>

                    </div>

                    <div className="nf-error-wrap">
                    </div>

                    </div>
                    </div>

                    <div className="nf-after-field">
                    <nf-section>


                    <div className="nf-input-limit">
                    </div>


                    <div id="nf-error-8" className="nf-error-wrap nf-error" role="alert">
                    </div>



                    </nf-section>
                    </div>

                    </div>

                    </nf-field>
                    </nf-fields-wrap>
                    </div>

                    <div className="nf-after-form-content">
                    <nf-section>


                    <div id="nf-form-errors-2" className="nf-form-errors" role="alert">
                    <nf-errors>
                    </nf-errors>
                    </div>

                    <div className="nf-form-hp">
                    <nf-section>

                    <label id="nf-label-field-hp-2" aria-hidden="true">

                    If you are a human seeing this field, please leave it empty.
                    <input id="nf-field-hp-2" name="nf-field-hp" className="nf-element nf-field-hp" type="text" value="" aria-labelledby="nf-label-field-hp-2" onChange={() => {}}/>

                    </label>

                    </nf-section>
                    </div>

                    </nf-section>
                    </div>

                    </div>

                    </form>
                    </div>

                    <div className="nf-after-form">
                    <nf-section>


                    </nf-section>
                    </div>

                    </div>

                    </div>
                    
                    <div className="fusion-clearfix">
                    </div>
                    </div>
                    </div>

                    <div className="fusion-layout-column fusion_builder_column fusion-builder-column-18 fusion_builder_column_2_5 2_5 fusion-two-fifth fusion-column-last" style={{ bgSize:'cover', width:'40%', width:`calc(40% - ( ( 4% ) * 0.4 ) )` }}>
                    <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
                    { isLoaded &&
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}> 

                                </GoogleMap>
                                }  
                            
                           

                       
                    <div className="fusion-sep-clear">
                    </div>
                    <div className="fusion-separator fusion-full-width-sep" style={{marginLeft: 'auto',marginRight: 'auto',marginTop:'10px',marginBottom:'10px',width:'100%' }}>
                    </div>
                    <div className="fusion-sep-clear">
                    </div>
                    <div className="fusion-text fusion-text-5">
                    <h4 data-fontsize="16" style={{ fontSize:16, lineHeight: 1.5, minFontSize:16 }} data-lineheight="24px" className="fusion-responsive-typography-calculated">
                    Koot Nel</h4>

                    <p>
                    T +27 23 3473983</p>

                    <p>
                    M +27 83 286 9223</p>

                    <p>
                    <a href="mailto:koot@tffgsa.co.za">
                    koot@tffgsa.co.za</a>
                    </p>

                    <p>
                    Babirwa Fruit Exports, Ambiance Farm</p>

                    <p>
                    PO Box 5307, Worcester-West, 6862<br />

                    Western Cape, South Africa</p>

                    </div>
                    <div className="fusion-clearfix">
                    </div>
                    </div>
                    </div>
                    <div className="fusion-layout-column fusion_builder_column fusion-builder-column-19 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{bgSize:'cover'}}>
                    <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
                    <div className="fusion-sep-clear">
                    </div>
                    <div className="fusion-separator fusion-full-width-sep" style={{marginLeft: 'auto',marginRight: 'auto',marginTop:'20px', marginBottom:'40px',width:'100%'}}>
                    </div>
                    <div className="fusion-sep-clear">
                    </div>
                    <div className="fusion-clearfix">
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    
    )
}