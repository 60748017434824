import { AboutFruitExports } from "./AboutFruitExports"
import { AmbianceFarm } from "./AmbianceFarm"
import { ContactBabirwa } from "./ContactBabirwa"
import { FarmLandFresh } from "./FarmLandFresh"
import { PackingHouse } from "./PackingHouse"

export const FusionContent = () => {
    return (
        <main id="main" className="clearfix width-100" style={{position: 'relative', zIndex: 3}}>
              <div className="fusion-row" style={{maxWidth:'100%'}}>
                <section id="content" className="full-width">
                    <div id="post-12" className="post-12 page type-page status-publish hentry">

                    <span className="entry-title rich-snippet-hidden">Home</span>
                    <span className="vcard rich-snippet-hidden">
                        <span className="fn">
                            <a href="https://babirwafruit.com/author/spkyn27uwvsezfdkjcc2/" title="Posts by Web Admin" rel="author">Web Admin</a>
                        </span>
                    </span>
                    <span className="updated rich-snippet-hidden">2020-01-06T10:29:28+02:00</span>
    				<div className="post-content">
                        <p></p>
                        <AboutFruitExports />
                        <FarmLandFresh />
                        <AmbianceFarm />
                        <FarmLandFresh isDefault={false} />
                        <PackingHouse />
                        <ContactBabirwa />
                        <p></p>

                    </div>
                    </div>
                </section>
              </div>
        </main>
    )
}