import logo from './assets/Long_Retina.png';
import './App.css';
import { SiteHeading } from './components/SiteHeading';
import { SlidersContainer } from './components/SlidersContainer';
import { FusionFooter } from './components/FusionFooter';
import { FusionContent } from './components/FusionContent';

function App() {
  return (
    <div className="boxed-wrapper">
       <div id="wrapper" className="fusion-wrapper">
           <div id="home" style={{position:'relative', top:'-1px'}}></div>
           <SiteHeading />
           <SlidersContainer />
           <FusionContent />
           <FusionFooter />
       </div>
    </div>
  );
}

export default App;
