import '../Sliders.css';
//import sliderBg from '../assets/DSC5762.jpg'
import sliderBg from '../assets/DSC5798.jpg'
import logo from '../assets/Long_Retina.png';

export const SlidersContainer = () => {
    return (
        <div id="sliders-container" className="fusion-slider-visibility">
            <div id="fusion-slider-3" data-id="3" className="fusion-slider-container fusion-slider-12" style={{height: '657px', maxWidth: '100%', maxHeight: '657px', marginTop: '-135px'}}>
                <div className="tfs-slider flexslider main-flex" style={{maxWidth: '100%', typography_sensitivity:1, height: '657px', position: 'fixed', visibility: 'visible',}} data-slider_width="100%" data-slider_height="600px" data-full_screen="1" data-parallax="1" data-nav_arrows="0" data-nav_box_width="63px" data-nav_box_height="63px" data-nav_arrow_size="25px" data-autoplay="1" data-loop="0" data-orderby="date" data-order="DESC" data-animation="fade" data-slideshow_speed="7000" data-animation_speed="600" data-typo_sensitivity="1" data-typo_factor="1.5" data-slider_indicator="" data-slider_indicator_color="#ffffff">
                <ul className="slides" style={{maxWidth: '100%', width: '100%'}}>
                    <li className="slide-id-22 flex-active-slide" data-mute="yes" data-loop="yes" data-autoplay="yes" style={{width: '100%', float: 'left', marginRight: '-100%', position: 'relative', opacity: 1, display: 'block', zIndex: 2}}>
                    <div className="slide-content-container slide-content-center" style={{paddingTop: '135px'}}>
                        <div className="slide-content" style={{marginTop: '0px', opacity: '1'}}>
                            <div className="heading ">
                            <div className="fusion-title-sc-wrapper" >
                            <div className="fusion-title title fusion-title-1 fusion-sep-none fusion-title-center fusion-title-text fusion-title-size-one" style={{ awbMarginBottom:'0px', awbFontSize:'80px'}}><h1 className="title-heading-center fusion-responsive-typography-calculated" style={{margin: '0px', fontSize: '1em', lineHeight: '1.2', fontSize:80, typography_sensitivity:1}} data-fontsize="80" data-lineheight="96px">Welcome to<br />Babirwa Fruit Exports</h1></div>									</div>
                            </div>
                        </div>
                    </div>
                    <div className="background background-image" style={{backgroundImage: `url(${sliderBg})`, maxWidth: '100%', height: '657px', width: '100%'}} data-imgwidth="1500">
                     </div>

                    </li>
                </ul>
                </div>
            </div>
        </div>
    )
}
