import farmLandBg from '../assets/DSC5783.jpg';

export const FarmLandFresh = ({isDefault=true}) => {
    {
    if(isDefault)
    return (
        <div className="fusion-fullwidth fullwidth-box fusion-builder-row-2 fusion-parallax-fixed nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{borderRadiusTopLeft:'0px',borderRadiusTopRight:'0px',borderRadiusBottomRight:'0px', borderRadiusBottomLeft:'0px',paddingTop:'50px',paddingBottom:'50px', backgroundColor:`rgba(0,0,0,0.44)`, backgroundImage:`url(${farmLandBg})`, backgroundSize : 'cover', backgroundAttachment:'fixed'}}>
            <div className="fusion-builder-row fusion-row">
                <div className="fusion-layout-column fusion_builder_column fusion-builder-column-4 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{bgSize:'cover'}}>
                    <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
                    <div className="fusion-title title fusion-title-2 fusion-title-center fusion-title-text fusion-title-size-one" style={{color:'#fff'}}>
                    <div className="title-sep-container title-sep-container-left">
                    <div className="title-sep sep-double sep-solid" style={{borderColor:'#e0dede'}}>
                    </div>
                    </div>
                    <span className="awb-title-spacer">
                    </span>
                    <h1 className="title-heading-center fusion-responsive-typography-calculated" style={{margin: '0px', fontSize:36, lineHeight: 1.4, color:'#ffffff'}} data-fontsize="36" data-lineheight="50.4px">
                    FARMLAND FRESH</h1>
                    <span className="awb-title-spacer">
                    </span>
                    <div className="title-sep-container title-sep-container-right">
                    <div className="title-sep sep-double sep-solid" style={{borderColor:'#e0dede'}}>
                    </div>
                    </div>
                    </div>
                        <div className="fusion-clearfix">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
    if(!isDefault)
    return (
        <div className="fusion-fullwidth fullwidth-box fusion-builder-row-2 fusion-parallax-fixed nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{borderRadiusTopLeft:'0px',borderRadiusTopRight:'0px',borderRadiusBottomRight:'0px', borderRadiusBottomLeft:'0px',paddingTop:'50px',paddingBottom:'50px', backgroundColor:`rgba(0,0,0,0.44)`, backgroundImage:`url(${farmLandBg})`, backgroundSize : 'cover', backgroundAttachment:'fixed'}}>
            <div className="fusion-builder-row fusion-row">
                <div className="fusion-layout-column fusion_builder_column fusion-builder-column-4 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{bgSize:'cover'}}>
                    <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
                    <div className="fusion-title title fusion-title-2 fusion-title-center fusion-title-text fusion-title-size-one" style={{color:'#fff'}}>
                    <div className="title-sep-container title-sep-container-left">
                    <div className="title-sep sep-double sep-solid" style={{borderColor:'#e0dede'}}>
                    </div>
                    </div>
                    <span className="awb-title-spacer">
                    </span>
                    <h1 className="title-heading-center fusion-responsive-typography-calculated" style={{margin: '0px', fontSize:36, lineHeight: 1.4, color:'#ffffff'}} data-fontsize="36" data-lineheight="50.4px">
                    PACKING HOUSE</h1>
                    <span className="awb-title-spacer">
                    </span>
                    <div className="title-sep-container title-sep-container-right">
                    <div className="title-sep sep-double sep-solid" style={{borderColor:'#e0dede'}}>
                    </div>
                    </div>
                    </div>
                        <div className="fusion-clearfix">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    ) }
}