import sideImage from '../assets/DSC5783-1.jpg'
export const AboutFruitExports = () => {
    return ( 
               
        <div id="About" className="fusion-container-anchor">
        <div className="fusion-fullwidth fullwidth-box fusion-builder-row-1 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{awbBorderRadiusTopLeft:'0px', awbBorderRadiusTopRight:'0px', awbBorderRadiusBottomRight:'0px', awbBorderRadiusBottomLeft:'0px'}}>
        <div className="fusion-builder-row fusion-row">
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-0 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{awbBgSize:'cover'}}>
        <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
        <div className="fusion-menu-anchor" id="about">
        </div>
        <div className="fusion-clearfix">
        </div>
        </div>
        </div>
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-1 fusion_builder_column_3_5 3_5 fusion-three-fifth fusion-column-first" style={{awbBgSize:'cover', width:'60%',width:`calc(60% - ( ( 4% ) * 0.6 ) )`,marginRight: '4%'}}>
        <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
        <div className="fusion-text fusion-text-1">
        <h2 data-fontsize="26" style={{fontSize:26, lineHeight: 1.5 }} data-lineheight="39px" className="fusion-responsive-typography-calculated">
        About Babirwa Fruit Exports</h2>
        
        <p style={{textAlign: "left"}}>
        At Babirwa Fruit Exports delicious grapes from the Cape are nurtured on the vine with a sustainable agricultural model that delivers a consistently superiour quality product.</p>
        
        <p style={{textAlign: "left"}}>
        Nestled in the rich farmland of Worcester, Babirwa Fruit Exports has created a best-of-breed table grape growing, packing and value-add facility capable of consistently supplying top retail outlets.</p>
        
        <p style={{textAlign: "left"}}>
        Business leader and entrepreneur Tebogo Mogashoa purchased the farming operations now named Babirwa Fruit Exports in 2016 and immediately set out to improve and enhance the farm’s produce and value-add packing and delivery operations.</p>
        
        <p style={{textAlign: "left"}}>
        The farming operation provides 60 permanent staffing positions and up to 300 temporary job opportunities in season.</p>
        
        <p style={{textAlign: "left"}}>
        In addition to the many job opportunities provided to the local communities of Worcester, De Doorns and Wolseley, Babirwa Fruit Exports also assists the nearby primary school with the provision of potable running water.</p>
        
        <p>
        Babirwa Fruit Exports is a Level 1 BEE company.</p>
        
        <p>
        Babirwa Fruit Exports has an official Sustainable Agriculture in South Africa (SIZA) audit confirmation in the platinum risk category. Download our certificate below in our Packing House section.</p>
        
        <p>
        Babirwa Fruit Exports is GLOBALG.A.P. certified. Download our certificate below in our Packing House section.</p>
        
        </div>
        <div className="fusion-clearfix">
        </div>
        </div>
        </div>
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-2 fusion_builder_column_2_5 2_5 fusion-two-fifth fusion-column-last" style={{awbBgSize:'cover',width:'40%',width:`calc(40% - ( ( 4% ) * 0.4 ) )`}}>
        <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
        <div className="fusion-image-element fusion-image-align-center in-legacy-container" style={{textAlign:'center',awbCaptionTitleSize:`var(--h2_typography-font-size)`,awbCaptionTitleTranform:`var(--h2_typography-text-transform)`,awbCaptionTitleLineHeight:`var(--h2_typography-line-height)`,awbCaptionTitleLetterSpacing:`var(--h2_typography-letter-spacing)`}}>
        <div className="imageframe-align-center">
        <span className=" fusion-imageframe imageframe-dropshadow imageframe-1 hover-type-zoomin" style={{WebkitBoxShadow: `5px 5px 9px rgba(0,0,0,0.3)`,boxShadow: `5px 5px 9px rgba(0,0,0,0.3)` }}>
        <img decoding="async" width="1417" height="945" src={sideImage} alt="" className="img-responsive wp-image-35" sizes="(max-width: 800px) 100vw, 600px"/>
        </span>
        </div>
        </div>
        <div className="fusion-clearfix">
        </div>
        </div>
        </div>
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-3 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{awbBgSize:'cover'}}>
        <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
        <div className="fusion-sep-clear">
        </div>
        <div className="fusion-separator fusion-full-width-sep" style={{marginLeft: 'auto',marginRight: 'auto',marginTop:'10px',marginBottom:'10px',width:'100%' }}>
        </div>
        <div className="fusion-sep-clear">
        </div>
        <div className="fusion-clearfix">
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
    )
}