import bgImage from '../assets/20190612_091943-1024x768.jpg';

export const PackingHouse = () => {
    const imagesList = ['20190531_161200.jpg','20190612_091522.jpg','20190612_091602-1.jpg','20190612_091831.jpg','20190612_091943-1.jpg',
    'IMG_20190114_073823.jpg','IMG_20190114_073841.jpg','IMG_20190114_074951.jpg','IMG_20190114_110925.jpg',
    'IMG_20190114_110942.jpg','IMG_20190114_110956.jpg','IMG_20190114_111347.jpg','IMG_20190114_112602.jpg',
    'IMG_20190114_120247.jpg','IMG-20190201-WA0013.jpg','IMG-20190201-WA0014.jpg',
    'IMG-20190213-WA0013.jpg','IMG-20190213-WA0018.jpg']

    const imagePositions = [
        { left: '0px', top: '0px'},
        { left: '370px', top: '0px'},
        { left: '740px', top: '0px'},
        { left: '0px', top: '280px'},
        { left: '370px', top: '280px'},
        { left: '740px', top: '280px'},
        { left: '0px', top: '560px'},
        { left: '370px', top: '560px'},
        { left: '740px', top: '770px'},
        { left: '0px', top: '1050px'},
        { left: '370px', top: '1050px'},
        { left: '740px', top: '1260px'},
        { left: '0px', top: '1540px'},
        { left: '370px', top: '1540px'},
        { left: '740px', top: '1750px'},
        { left: '0px', top: '2030x'},
        { left: '370px', top: '2030px'},
        { left: '740px', top: '2120px'}
    ]

    return (
        <div id="Ambiance2" className="fusion-container-anchor">
<div className="fusion-fullwidth fullwidth-box fusion-builder-row-5 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ borderRadiusTopLeft:'0px', borderRadiusTopRight:'0px', borderRadiusBottomRight:'0px', borderRadiusBottomLeft:'0px'}}>
<div className="fusion-builder-row fusion-row">
<div className="fusion-layout-column fusion_builder_column fusion-builder-column-11 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ bgSize: 'cover' }}>
<div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
<div className="fusion-menu-anchor" id="packinghouse">
</div>
<div className="fusion-clearfix">
</div>
</div>
</div>
<div className="fusion-layout-column fusion_builder_column fusion-builder-column-12 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ bgSize: 'cover' }}>
<div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
<div className="fusion-sep-clear">
</div>
<div className="fusion-separator fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop:'10px', marginBottom:'10px', width:'100%'}}>
</div>
<div className="fusion-sep-clear">
</div>
<div className="fusion-clearfix">
</div>
</div>
</div>
<div className="fusion-layout-column fusion_builder_column fusion-builder-column-13 fusion_builder_column_3_5 3_5 fusion-three-fifth fusion-column-first" style={{ bgSize:'cover', width:'60%', width:`calc(60% - ( ( 4% ) * 0.6 ) )`, marginRight: '4%' }}>
<div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
<div className="fusion-text fusion-text-3">
<h2 data-fontsize="26" style={{ fontSize:26, lineHeight: 1.5}} data-lineheight="39px" className="fusion-responsive-typography-calculated">
Babirwa Packing House</h2>

<p>
The large Babirwa Fruit Exports packing plant uses the most up-to-date technology in the industry. The cooling facility is state-of-the-art and the plant features 68 packaging stations across four production lines with in-line scaling and productivity measurement tools.</p>

<p>
Operationally Babirwa Fruit Exports aims to produce 5 000 cartons of table grapes per hectare, totalling around 250 000 cartons of 4.5kg equivalent each.</p>

<p style={{ textAlign : 'left'}}>
Once our grapes have been harvested they are transported to our large on-site packing house plant where the grapes are immediately cooled to 18℃. Once the grapes are cool and ready for packing, they are conveyed to our 68 packing stations via four lines covering 17 stations each.</p>

<p>
Every packing station features a modern programmable smart scale, as well as a screen to monitor workload and productivity. Waste is measured and managed continuously. Each station is occupied by two workers, with a supervisor and quality control officer assigned to units of five stations. Our two packing managers cover two lines each.</p>

<p style={{ textAlign : 'left'}}>
After packing our cartons are closed with a sulphur dioxide (SO2) pad. Cartons are then labelled and packed onto pallets. Various inspections are conducted, after which the pallets of packed cartons are cooled to 10℃ and dispatched to cold chain distribution centres.</p>

</div>
<div className="fusion-alignleft">
<a className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-2 fusion-button-default-span fusion-button-default-type" style={{ button_accent_color:'#ffffff', button_accent_hover_color:'#ffffff', button_border_hover_color:'#ffffff', buttonBorderRadiusTopLeft:'3px', buttomBborderRadiusTopRight:'3px', buttonBorderRadiusBottomRight:'3px', buttonBorderRadiusBottomLeft:'3px', button_gradient_top_color:'#d8b258', button_gradient_bottom_color:'#d8b258', button_gradient_top_color_hover:'#c39832', button_gradient_bottom_color_hover:'#c39832'}} target="_blank" rel="noopener noreferrer" href="../assets/AMBIANCE-SIZA-audit-report-confirmation-2019-02-25.pdf">
<span className="fusion-button-text">
Babirwa SIZA Audit Confirmation</span>
</a>
</div>
<div className="fusion-alignleft">
<a className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-3 fusion-button-default-span fusion-button-default-type" style={{ button_accent_color:'#ffffff', button_accent_hover_color:'#ffffff', button_border_hover_color:'#ffffff', buttonBorderRadiusTopLeft:'3px', buttomBborderRadiusTopRight:'3px', buttonBorderRadiusBottomRight:'3px', buttonBorderRadiusBottomLeft:'3px', button_gradient_top_color:'#d8b258', button_gradient_bottom_color:'#d8b258', button_gradient_top_color_hover:'#c39832', button_gradient_bottom_color_hover:'#c39832'}} target="_blank" rel="noopener noreferrer" href="../assets/AMBIANCE-GlobalGAP-CertificationIssueReport-2020.pdf">
<span className="fusion-button-text">
Babirwa GLOBALG.A.P. Certification</span>
</a>
</div>
<div className="fusion-clearfix">
</div>
</div>
</div>
<div className="fusion-layout-column fusion_builder_column fusion-builder-column-14 fusion_builder_column_2_5 2_5 fusion-two-fifth fusion-column-last" style={{ bgSize:'cover', width:'40%', width:`calc(40% - ( ( 4% ) * 0.4 ) )`}}>
<div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
<div className="fusion-image-element fusion-image-align-center in-legacy-container" style={{ textAlign:'center', captionTitleSize:`var(--h2_typography-font-size)`, captionTitleTransform:`var(--h2_typography-text-transform)`, captionTitleLineHeight:`var(--h2_typography-line-height)`, captionTitleLetterSpacing:`var(--h2_typography-letter-spacing)`}}>
<div className="imageframe-align-center">
<span className=" fusion-imageframe imageframe-dropshadow imageframe-3 hover-type-zoomin" style={{ WebkitBoxShadow: `5px 5px 9px rgba(0,0,0,0.3)`, boxShadow: `5px 5px 9px rgba(0,0,0,0.3)` }}>
<img decoding="async" width="1024" height="768" title="20190612_091943" src={bgImage} alt="" className="img-responsive wp-image-112" sizes="(max-width: 800px) 100vw, 600px" />
</span>
</div>
</div>
<div className="fusion-clearfix">
</div>
</div>
</div>
<div className="fusion-layout-column fusion_builder_column fusion-builder-column-15 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ bgSize: 'cover' }}>
<div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
<div className="fusion-sep-clear">
</div>
<div className="fusion-separator fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop:'20px', marginBottom:'40px', width:'100%'}}>
</div>
<div className="fusion-sep-clear">
</div>
<div className="awb-gallery-wrapper awb-gallery-wrapper-2 button-span-no">
<div style={{ margin: '-5px', bordersize:'0px', position: 'relative',  height: '2520.14px'}} className="fusion-gallery fusion-gallery-container fusion-grid-3 fusion-columns-total-18 fusion-gallery-layout-grid fusion-gallery-2">

<div style={{ display: 'flex', margin: '-5px',  bordersize:'0px',  position: 'relative',  height: '1000.29px', justContent: 'space-between' }} className="fusion-gallery fusion-gallery-container fusion-grid-3 fusion-columns-total-12 fusion-gallery-layout-grid fusion-gallery-1">
            
            { imagesList.map((imageSrc, index) =>  <div key={index} style={{ padding: '5px', display: 'block', position: 'absolute', left: imagePositions[index].left, top: imagePositions[index].top}} className="fusion-grid-column fusion-gallery-column fusion-gallery-column-3 hover-type-zoomin">
            <div className="fusion-gallery-image">
                <a  href={"../assets/" + imageSrc } rel="noreferrer" data-rel={`iLightbox[gallery_image_${index}]`} className="fusion-lightbox" target="_self" data-caption="">
                    <img decoding="async" src={"../assets/" + imageSrc } width="1417" height="945" alt="" title="" aria-label="" className="img-responsive wp-image-29" sizes="(min-width: 2200px) 100vw, (min-width: 784px) 363px, (min-width: 712px) 545px, (min-width: 640px) 712px, " />
                </a>
            </div>
            </div> 
            
            )}

            {/* <div className="clearfix"></div> */}

</div>

<div className="clearfix">
</div>
</div>
</div>

<div className="fusion-sep-clear"></div>
<div className="fusion-separator fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop:'20px', marginBottom:'40px', width:'100%'}}>
</div>
<div className="fusion-sep-clear">
</div>
<div className="fusion-clearfix">
</div>
</div>
</div>
</div>
</div>
</div>
    )
}